/* eslint-disable */

export default (a, b) => {
  // if both teachers don't change
  if (a.selected && b.selected) return 0;
  // if b is selected move it up
  if (b.selected) return 1;
  // if a is selected move it up
  if (a.selected) return -1;
  // if they are the same type sort by created
  if (a.type === b.type) {
    return a.createdAt > b.createdAt ? 1 : -1;
  }
  // if B is an instrument move it up
  if (b.type === 'I') return 1;
  // otherwise move down
  return -1;
};