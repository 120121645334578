/* eslint-disable */

export default (filteringOn, array = []) => {
  const filteringKeys = Object.keys(filteringOn).filter(
    key => filteringOn[key]
  );

  return array
    .filter(member => {
      return filteringKeys.reduce((acc, key) => {
        return acc && member.matches[key];
      }, true);
    })
    .sort((a, b) => {
      if (a.sortOn === b.sortOn) return 0;
      return a.sortOn > b.sortOn ? 1 : -1;
    });
};
