/* eslint-disable */
import { FilterListStyled } from '../Directory.Styled';

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ContextDirectory } from '../context-directory';
import InputCheckBox from '../InputCheckBox';

export default function List({ height, items, open, type }) {
  const { filters, setFilters } = useContext(ContextDirectory);
  return (
    <FilterListStyled height={height} open={open}>
      {items
        .sort((a, b) => {
          if (typeof a.name === 'object') return a.code > b.code ? 1 : -1;
          return a.name > b.name ? 1 : -1;
        })
        .map(item => (
          <InputCheckBox
            labelCSS={{ margin: 0, padding: 0 }}
            labelText={item.name}
            key={item.code}
            inputValue={item.code}
            inputOnChange={e => {
              setFilters({ add: e.target.checked, filter: item.code, type });
            }}
            checkOn={filters[type].find(code => code === item.code)}
          />
        ))}
    </FilterListStyled>
  );
}

List.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

List.defaultProps = {
  height: '20vh',
  items: [],
  type: '',
};
