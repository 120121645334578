/* eslint-disable */

import {
  SearchContainerStyled,
  SearchIconStyled,
  SearchInputStyled,
} from './Directory.Styled';

import React, { useContext } from 'react';

import { ContextDirectory } from './context-directory';

export default function MemberDirectorySearchBar() {
  const { search, setSearch } = useContext(ContextDirectory);
  return (
    <SearchContainerStyled>
      <SearchIconStyled />
      <SearchInputStyled
        onChange={e => setSearch(e.currentTarget.value)}
        value={search}
        name="search"
        placeholder="Search By Name"
      />
    </SearchContainerStyled>
  );
}
