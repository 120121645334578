/* eslint-disable */

import {
  AvatarStyled,
  BioStyled,
  CardInfoStyled,
  MemberCardStyled,
  NameStyled,
  TagArrowStyled,
  TagsStyled,
  TagStyled,
  WebsiteLinkStyled,
} from '../Directory.Styled';

import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ContextDirectory } from '../context-directory';
import sortTags from '../sortTags';
import Contractor from '../SVGs/Contractor';
import Teacher from '../SVGs/Teacher';

const PublicDirectoryCard = ({ member }) => {
  const { filters } = useContext(ContextDirectory);
  const tagRef = useRef();
  const [showButton, setShowButton] = useState(false);
  const [showTags, setShowTags] = useState(false);

  useEffect(() => {
    if (tagRef.current) {
      const el = tagRef.current;
      const isOverflowing =
        el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
      setShowButton(isOverflowing);
    }
  }, [tagRef.current]);

  // clean up instruments and styles into tags
  const tags = member.userInstruments.reduce((acc, instrument) => {
    const ts = [...acc];
    const code = instrument.instruments.name;
    const selected =
      filters.instruments.length &&
      !!filters.instruments.find(i => i === instrument.instruments.code);
    if (instrument.teach) {
      ts.push({
        code,
        selected: filters.functions.includes('teach') && instrument.teach,
        teach: true,
      });
    }
    if (instrument.play) {
      ts.push({
        code,
        selected:
          selected &&
          (!filters.functions.includes('teach') || !instrument.teach),
      });
    }
    return ts;
  }, []);
  tags.push(
    ...member.musicStyles.map(style => ({
      code: style.name,
      selected:
        !!filters.musicStyles.length &&
        !!filters.musicStyles.find(code => code === style.code),
    }))
  );
  // sort the tags so the selected ones are first
  // eslint-disable-next-line no-nested-ternary
  tags.sort(sortTags);
  return (
    <MemberCardStyled>
      <CardInfoStyled css={{ width: '100% !important' }}>
        <AvatarStyled
          src={
            (process.env.NEXT_PUBLIC_ASSET_URL || process.env.GATSBY_PUBLIC_ASSET_URL) +
            (member.avatar || '1592346343552.png')
          }
          height="130"
          width="130"
        />
        <div>
          <NameStyled>
            {member.professionalName}
            {member.contractor && <Contractor />}
            {member.website && (
              <WebsiteLinkStyled
                href={member.website}
                target="_blank"
                rel="noreferrer"
              >
               Website
              </WebsiteLinkStyled>
            )}
            {member.email && (
              <WebsiteLinkStyled

                target="_blank"
                rel="noreferrer"
                css={{ marginRight: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location = 'mailto:' + member.email
                }}
              >
                Email
              </WebsiteLinkStyled>
            )}
          </NameStyled>
          <TagsStyled ref={tagRef} open={showTags}>
            {tags.map(tag => (
              <TagStyled key={tag.code + tag.teach} dark={tag.selected}>
                {tag.teach && <Teacher />}
                {tag.code}
              </TagStyled>
            ))}

            {showButton && (
              <TagArrowStyled
                open={showTags}
                onClick={() => setShowTags(!showTags)}
              >
                &#10095;
              </TagArrowStyled>
            )}
          </TagsStyled>
          <BioStyled display={!showTags}>{member.bio}</BioStyled>
        </div>
      </CardInfoStyled>
    </MemberCardStyled>
  );
};

PublicDirectoryCard.propTypes = {
  member: PropTypes.shape({
    address: PropTypes.shape({
      formatted: PropTypes.string,
    }),
    avatar: PropTypes.string,
    bio: PropTypes.string,
    contractor: PropTypes.bool,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    musicStyles: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    phone: PropTypes.string,
    professionalName: PropTypes.string,
    userInstruments: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        play: PropTypes.bool,
        teach: PropTypes.bool,
      })
    ),
    website: PropTypes.string,
  }),
};

PublicDirectoryCard.defaultProps = {
  member: {},
};
export default PublicDirectoryCard;
