/* eslint-disable */
import React from 'react';

export default function Contractor() {
  return (
    <svg
      width="19"
      height="11"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ fill: 'currentColor', marginRight: 5, verticalAlign: 'baseline' }}
    >
      <path d="M15 23C23.2843 23 30 16.2534 30 7.93103C30 7.72726 29.996 7.52444 29.988 7.32264C29.7313 6.3675 29.3393 5.52393 28.7195 4.83482C27.0968 3.03066 24.7535 1.94044 22.5567 0.918435L22.2874 0.793094L20.9211 2.77585L19.8583 4.50224L18.839 6.833L17.7358 15.1115L16.9737 4.83482L15.0777 5.94827L13.0263 4.83482L12.2692 15.0429L11.1555 6.833L10.1417 4.50224L9.07895 2.77585L7.71255 0.793094L7.42974 0.924726C5.23649 1.94492 2.89437 3.03436 1.27496 4.83482C0.660216 5.5183 0.269673 6.35373 0.0129439 7.29926C0.00434285 7.50878 0 7.71939 0 7.93103C0 16.2534 6.71573 23 15 23Z" />
      <path d="M17.3684 2.37931L15 3.70914L12.6316 2.37931V0H17.3684V2.37931Z" />
    </svg>
  );
}
