/* eslint-disable */
import {
  FilteredListContainerStyled,
  FilterListTitleStyled,
  IndicatorStyled,
} from '../Directory.Styled';

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { ContextDirectory } from '../context-directory';

import List from './List';
import Selection from './Selection';

export default function MemberDirectoryFilters() {
  const { filterData } = useContext(ContextDirectory);
  const [toggles, setToggles] = useState(
    Object.keys(filterData).reduce((acc, key) => ({ ...acc, [key]: [] }), {
      filters: true,
    })
  );
  return (
    <FilteredListContainerStyled>
      <FilterListTitleStyled
        onClick={() => setToggles({ ...toggles, filters: !toggles.filters })}
        sm
      >
        Filters
        <IndicatorStyled toggle={toggles.filters} />
      </FilterListTitleStyled>
      <Selection height="auto" open={toggles.filters} />
      {Object.keys(filterData)
        .sort()
        .map(key => {
          let listHeight = '20vh';
          if (filterData[key].length < 10) listHeight = 'auto';
          return (
            <>
              <FilterListTitleStyled
                onClick={() => setToggles({ ...toggles, [key]: !toggles[key] })}
                sm
              >
                {key.replace(/([A-Z])/g, ' $1')}
                <IndicatorStyled toggle={toggles[key]} />
              </FilterListTitleStyled>
              <List
                items={filterData[key]}
                height={listHeight}
                open={toggles[key]}
                type={key}
              />
            </>
          );
        })}
    </FilteredListContainerStyled>
  );
}

MemberDirectoryFilters.propTypes = {
  filters: PropTypes.shape({
    instruments: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    musicStyles: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
};
MemberDirectoryFilters.defaultProps = {
  filters: {},
};
