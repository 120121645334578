/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function Close({ className }) {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1.00005 1.00051L7.3656 7.30154" stroke="white" />
      <path d="M6.99997 1.0003L0.634466 7.30173" stroke="white" />
    </svg>
  );
}

Close.propTypes = {
  className: PropTypes.string,
};

Close.defaultProps = {
  className: '',
};
