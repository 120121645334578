/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';

import { ContextDirectory } from './context-directory';
import Filters from './Filters';
import Pagination from './Pagination';

export default function MemberDirectory({ Card, filter, members }) {
  const { filters, search } = useContext(ContextDirectory);
  const filteredMembers = filter(filters, search, members);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [filters, search]);

  return (
    <div
      css={css`
        display: grid;
        grid-column-gap: 30px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        width: 100%;
        max-width: 1200px;
        margin: 45px auto;


        @media (max-width: 991.98px) {
          grid-column-gap: 0;
          margin: 0;
          padding: 0;
        }
      `}
    >
      <div
        css={css`
          grid-column: span 4;

          @media (max-width: 991.98px) {
            grid-column: span 12;
          }
        `}
      >
        <Filters />
      </div>
      <div
        css={css`
          grid-column: span 8;

          @media (max-width: 991.98px) {
            grid-column: span 12;
          }
        `}
      >
        <Pagination
          showResults={filteredMembers.length !== members.length}
          results={filteredMembers.length}
          page={page}
          setPage={setPage}
        />
        {filteredMembers.slice((page - 1) * 15, page * 15).map(member => {
          if (member.website && !member.website.includes('http'))
            member.website = `http://${member.website}`;
          return <Card member={member} key={member.id} />;
        })}
        <Pagination
          showResults={filteredMembers.length !== members.length}
          results={filteredMembers.length}
          page={page}
          setPage={setPage}
          noSearch
        />
      </div>
    </div>
  );
}

MemberDirectory.propTypes = {
  Card: PropTypes.element.isRequired,
  filter: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.object),
};

MemberDirectory.defaultProps = {
  members: [],
};
