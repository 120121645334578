/* eslint-disable */

import { ArrowStyled, PagesStyled, PaginationStyled } from './Directory.Styled';

import React from 'react';
import PropTypes from 'prop-types';

import MemberDirectorySearchBar from './SearchBar';

export default function MemberDirectoryPagination({
  noSearch,
  page,
  results,
  setPage,
  showResults,
}) {
  const total = Math.max(1, Math.ceil(results / 15));
  return (
    <PaginationStyled css={{ marginTop: noSearch ? 20 : 0 }}>
      <PagesStyled>
        {showResults && `(${results} Result${results === 1 ? '' : 's'})`}
      </PagesStyled>
      {!noSearch && <MemberDirectorySearchBar />}
      <PagesStyled>
        <ArrowStyled
          left
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        />
        {`${page} of ${total}`}
        <ArrowStyled
          right
          disabled={page === total}
          onClick={() => setPage(page + 1)}
        />
      </PagesStyled>
    </PaginationStyled>
  );
}

MemberDirectoryPagination.propTypes = {
  noSearch: PropTypes.bool,
  page: PropTypes.number,
  results: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  showResults: PropTypes.bool,
};

MemberDirectoryPagination.defaultProps = {
  noSearch: false,
  page: 1,
  results: 0,
  showResults: true,
};
