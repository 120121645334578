/* eslint-disable */

import filterSortDirectory from './filterSortDirectory';

export default (filters, search, members) => {
  const filteringOn = {
    contractor: filters.functions.includes('contractor'),
    instrument: !!filters.instruments.length,
    search: !!search,
    style: !!filters.musicStyles.length,
    teacher: filters.functions.includes('teach'),
  };

  return filterSortDirectory(
    filteringOn,
    members.map(member => {
      member.matches = {
        contractor: false,
        instrument: false,
        search: false,
        style: false,
        teacher: false,
      };

      member.sortOn = `${member.lastName}, ${member.firstName}`.toLowerCase();

      // if there is a search term, check if it's in the name
      if (filteringOn.search) {
        // check if the search term is in any of the parts
        member.matches.search = `${member.firstName} ${member.lastName} ${member.professionalName}`.toLowerCase().includes(search.toLowerCase());
      }

      // if there is a function filter
      if (filteringOn.teacher) {
        // find if the member teaches any instrument
        const teacher = member.userInstruments.find(
          instrument => instrument.teach
        );
        // filter out anyone who doesn't match the function type
        member.matches.teacher = !!teacher;
      }

      if (filteringOn.contractor) {
        // if the function includes contractor
        member.matches.contractor = member.contractor;
      }

      // if we are filtering by instrument
      if (filteringOn.instrument) {
        // if they match all the instruments selected and the function type
        const matchedInstruments = member.userInstruments.filter(instrument =>
          filters.instruments.find(code => instrument.instruments.code === code)
        );

        // only matches teacher if they teach ALL the instruments
        if (filteringOn.teacher) {
          member.matches.teacher =
            matchedInstruments.filter(i => i.teach).length ===
            matchedInstruments.length;
        }

        // does the member match ALL the instruments
        member.matches.instrument =
          matchedInstruments.length === filters.instruments.length;

        // does the member match ANY of the instruments
        // member.matches.instrument = !!matchedInstruments.length;
      }

      // if we are filtering on style
      if (filteringOn.style) {
        const matchedStyles = filters.musicStyles.filter(code => {
          return member.musicStyles.find(style => style.code === code);
        });
        // does the member match ALL the styles
        member.matches.style =
          matchedStyles.length === filters.musicStyles.length;

        // does the member match ANY of the styles
        // member.matches.style = !!matchedStyles.length;
      }
      return member;
    })
  );
};
