/* eslint-disable */
import {
  CloseBtnStyled,
  SelectionBoxStyled,
  SelectionTagStyled,
} from '../Directory.Styled';

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ContextDirectory } from '../context-directory';
import Close from '../SVGs/Close';

export default function Selection({ height, open }) {
  const { filterData, filters, setFilters } = useContext(ContextDirectory);
  const filterDataArray = Object.keys(filterData).reduce(
    (acc, key) => [...acc, ...filterData[key]],
    []
  );
  const filterArray = Object.keys(filters).reduce(
    (acc, key) => [...acc, ...filters[key]],
    []
  );
  const removeFilter = filter => () => {
    const type = Object.keys(filters).find(key =>
      filters[key].find(code => code === filter)
    );
    setFilters({ add: false, filter, type });
  };
  return (
    <SelectionBoxStyled height={height} open={open}>
      {filterArray.map(filter => (
        <SelectionTagStyled key={filter}>
          <CloseBtnStyled type="button" onClick={removeFilter(filter)}>
            <Close />
          </CloseBtnStyled>
          <>{filterDataArray.find(({ code }) => code === filter).name}</>
        </SelectionTagStyled>
      ))}
      {!filterArray.length && (
        <p css={{ textAlign: 'center' }}>No Filters Selected</p>
      )}
    </SelectionBoxStyled>
  );
}

Selection.propTypes = {
  height: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

Selection.defaultProps = {
  height: 'auto',
};
