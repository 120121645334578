/* eslint-disable */

import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ContextDirectory = createContext();

export const ProviderDirectory = ({ children, filterData }) => {
  const [search, setSearch] = useState('');
  const [filters, setFilterState] = useState(
    Object.keys(filterData).reduce((acc, key) => ({ ...acc, [key]: [] }), {})
  );
  const setFilters = ({ add = true, filter, type }) => {
    if (add) {
      setFilterState({
        ...filters,
        [type]: [...filters[type], filter],
      });
    } else {
      setFilterState({
        ...filters,
        [type]: filters[type].filter(code => code !== filter),
      });
    }
  };
  return (
    <ContextDirectory.Provider
      value={{
        filterData,
        filters,
        search,
        setFilters,
        setSearch,
      }}
    >
      {children}
    </ContextDirectory.Provider>
  );
};

ProviderDirectory.defaultProps = {
  filterData: {},
};

ProviderDirectory.propTypes = {
  filterData: PropTypes.objectOf(PropTypes.array),
};

export default ProviderDirectory;
