/* eslint-disable */
import React from 'react';

import { css } from '@emotion/core';

export default function Teacher() {
  return (
    <svg
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        margin-right: 5px;

        vertical-align: baseline;

        fill: currentColor;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57938 6.64293L0.145212 3.23128C-0.0540214 3.15924 -0.0462068 2.82836 0.156127 2.76912L9.59046 0.00727004C9.62406 -0.00256773 9.65926 -0.00241894 9.6928 0.00770268L18.8453 2.7695C19.0456 2.82994 19.0535 3.15715 18.8565 3.2306L16.2898 4.18736V8.59981C16.2898 8.79565 16.182 8.96952 16.0221 9.03145C15.7687 9.12966 15.507 8.91037 15.507 8.59981V4.47914L9.70381 6.6423C9.66337 6.65737 9.61992 6.65759 9.57938 6.64293ZM16.2898 4.08378V4.07453L15.5414 4.3645L9.9692 2.94982C9.72264 2.88723 9.7174 2.48063 9.96224 2.40947C9.99813 2.39904 10.0356 2.39845 10.0717 2.40774L16.4095 4.03857L16.2898 4.08378Z"
      />
      <path d="M16.4204 10.3926V10.0607C16.4204 9.65013 16.0769 9.35812 15.7403 9.48262C15.5237 9.56272 15.3767 9.79653 15.3767 10.0607V10.3926C15.3767 10.8387 15.777 11.1323 16.1233 10.9401C16.3048 10.8394 16.4204 10.6264 16.4204 10.3926Z" />
      <path d="M4.4317 6.66546H4.40143V9.54706C4.40143 10.3009 6.62102 10.9121 9.35902 10.9121C12.097 10.9121 14.3166 10.3009 14.3166 9.54709V6.66546H14.2863C14.0123 7.34793 11.9108 7.87875 9.35902 7.87875C6.80723 7.87875 4.70574 7.34793 4.4317 6.66546Z" />
    </svg>
  );
}
